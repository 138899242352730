import { Route, Routes } from 'react-router-dom'
import { NewChat } from './NewChat'
import { Inbox } from './Inbox'
import { ThreadPage } from 'src/pages/Chat/Thread'
import { HelpChatPage } from 'src/pages/Chat/NewChat/HelpChat'

const ChatRouter = () => (
    <Routes>
        <Route path="" element={<Inbox />} />
        <Route path="/thread/:threadId" element={<ThreadPage />} />

        <Route path="/new" element={<NewChat />} />
        <Route path="/help" element={<HelpChatPage />} />
    </Routes>
)

export default ChatRouter
