import { QueryKey, useQuery, UseQueryOptions } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { useUserInfo } from 'src/hooks'

export type UncollectableBill = {
    collectable: false
    encounterId: string
}

export type CollectableBill = {
    collectable: true
    encounterId: string
    insuranceCoveredInCents: number
    patientAdjustmentsInCents: number
    patientBalanceInCents: number
    patientPaidInCents: number
    patientResponsibilityInCents: number
    processedAs: 'Insurance-pay' | 'Self-pay'
    remainingBalanceInCents: number
    totalChargesInCents: number
}

export type Bill = UncollectableBill | CollectableBill

export enum MockedBillEnum {
    INSURANCE_REMAINING = 'insurance_remaining',
    CASH_PAY_REMAINING = 'cash_pay_remaining',
    INSURANCE_NO_REMANING = 'insurance_no_remaining',
    CASH_PAY_NO_REMAINING = 'cash_pay_no_remaining',
    NON_COLLECTABLE = 'non_collectable'
}

const isProduction = window.location.hostname === 'member.asktia.com'

export async function getBillsForPatient(patientId: string): Promise<Bill[]> {
    let url = `${BASE_API_URL}/insurance-api/encounter?userId=${patientId}`

    if (!isProduction) {
        url += `&mockedBill=true`
    }

    const data = await fetcher(url)

    return [...data.results]
}

export async function getBillForEncounter(
    claimId?: string,
    mockedBill?: MockedBillEnum
): Promise<Bill> {
    let url = `${BASE_API_URL}/insurance-api/claim/${claimId}`

    if (mockedBill) {
        url += `?mockedBill=${mockedBill}`
    }

    const data = await fetcher(url)

    return {
        ...data.results
    }
}

export function useBillForEncounter(
    claimId: string,
    mockedBill?: MockedBillEnum,
    options?: Omit<
        UseQueryOptions<Bill, unknown, Bill, QueryKey>,
        'queryKey' | 'queryFn'
    >
) {
    const query = useQuery<Bill>(
        ['bill-for-encounter', claimId, mockedBill],
        () => getBillForEncounter(claimId, mockedBill),
        {
            ...options,
            staleTime: Infinity
        }
    )

    return {
        isLoading: query.isLoading,
        bill: query.data
    }
}

export function useBillsForPatient() {
    const { user, isLoading: userLoading } = useUserInfo()

    const query = useQuery<Bill[]>(
        ['bill-for-user'],
        () => getBillsForPatient(String(user?.id)),
        {
            staleTime: Infinity,
            enabled: !!user
        }
    )

    return {
        isLoading: query.isLoading,
        bills: query.data as Bill[]
    }
}
