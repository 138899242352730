import { Box, Grid, Paragraph, Spinner } from '@asktia/tia-ui'
import { useLocation, useParams } from 'react-router'
import { Show404 } from 'src/flows/404'
import { useAppointmentProfile } from 'src/flows/AppointmentBooking/useAppointmentProfile'
import { BookingCardDetails } from 'src/flows/AppointmentBooking/BookingCard'
import { AvailableSlots } from 'src/flows/AppointmentBooking/views/BookingTimeSelection/AvailableSlots'
import { useAppointmentSuggestion } from 'src/flows/AppointmentBooking/useAppointmentSuggestion'
import _ from 'lodash'
import { useAppointment } from 'src/hooks/useAppointment'
import { useRef } from 'react'
import { View } from 'src/components/Blocks/View'
import { Appointment, AppointmentSuggestion, QueryParams } from 'src/types'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'

// reads params from URL
function useBookingParams() {
    const { appointmentProfileUuid } = useParams<{
        appointmentProfileUuid: string
    }>()
    const searchParams = new URLSearchParams(useLocation().search)

    // undefined instead of null enables nicer typing
    // in hooks that take these as arguments
    const rescheduleAppointmentUuid =
        searchParams.get('reschedule') || undefined
    const appointmentSuggestionUuid = searchParams.get('context') || undefined

    return {
        appointmentProfileUuid,
        rescheduleAppointmentUuid,
        appointmentSuggestionUuid
    }
}

// gaggle of queries that fetches appointment data
// for initial and overriden filters
function useFilterQueries() {
    const { rescheduleAppointmentUuid, appointmentSuggestionUuid } =
        useBookingParams()

    const suggestionQuery = useAppointmentSuggestion(appointmentSuggestionUuid)

    const rescheduledAppointmentQuery = useAppointment(
        rescheduleAppointmentUuid
    )

    return {
        // Pick the appointment suggestion over the rescheduled appointment's suggestion
        suggestion: suggestionQuery.suggestion,
        rescheduledAppointment: rescheduledAppointmentQuery.appointment,
        isLoading:
            suggestionQuery.isLoading || rescheduledAppointmentQuery.isLoading
    }
}

// sets up filter overrides
function getFilterOverrides(
    suggestion?: AppointmentSuggestion,
    rescheduledAppointment?: Appointment
) {
    // We filter slots to specific providers because services require specific providers
    // For example Colposcopy require Dr Moritz or Devon and Therapy Returning
    // require the same provider as the one scheduled to the appointment being rescheduled
    const overrides: QueryParams = {}

    if (suggestion?.params.providerUuids) {
        overrides.providerUuids = suggestion?.params.providerUuids
    } else if (
        rescheduledAppointment?.requirePatientReschedulingWithSameProvider &&
        rescheduledAppointment?.scheduledProviderUuid
    ) {
        // reality guarantees that you requireProvider
        // and scheduledProvider go together
        // EN ticket makes this a hard requirement later
        // https://asktia.atlassian.net/browse/EN-266

        overrides.providerUuids = [rescheduledAppointment.scheduledProviderUuid]
    }

    if (rescheduledAppointment) {
        overrides.cadence = 'once'
    }

    return overrides
}

export const BookingTimeSelection = () => {
    // We cannot use useBookingFlow here because useBookingFlow tries to generate
    // nextPath with slot ids, but we don't know the slot yet
    // instead, each available slot can start a new flow with useBookingFlow(slot)

    const {
        appointmentProfileUuid,
        rescheduleAppointmentUuid,
        appointmentSuggestionUuid
    } = useBookingParams()

    const { profile: appointmentProfile, isLoading: profileLoading } =
        useAppointmentProfile(appointmentProfileUuid, appointmentSuggestionUuid)

    const {
        suggestion,
        rescheduledAppointment,
        isLoading: filterQueryLoading
    } = useFilterQueries()

    const filterOverrides = getFilterOverrides(
        suggestion,
        rescheduledAppointment
    )

    // used in AvailableSlots to scroll when loading
    const scrollRef = useRef<HTMLDivElement | null>(null)

    const isBookingMaintenanceMode =
        useAmpliFeatureFlag('booking-maintenance-mode') === 'on'

    if (profileLoading || filterQueryLoading) {
        return <Spinner />
    }

    if (!appointmentProfile) {
        return <Show404 redirectToCareCoordinator />
    }

    return (
        <View>
            <Grid
                gap={[6, 7]}
                sx={{
                    gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 2fr']
                }}
            >
                <Box>
                    <BookingCardDetails
                        appointmentProfile={appointmentProfile}
                        selectedTreatments={[]}
                        sx={{
                            border: '1px solid',
                            borderRadius: 2,
                            borderColor: 'oat'
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        mx: ['calc(50% - 50vw)', 'initial']
                    }}
                >
                    {isBookingMaintenanceMode ? (
                        <Box
                            sx={{
                                bg: 'primaryButton',
                                p: 5,
                                borderRadius: [0, 2],
                                // fixes mainBackground padding on mobile
                                mb: ['-24px', 'initial'],
                                color: 'white'
                            }}
                        >
                            <Paragraph>
                                Sorry, our booking service is down for scheduled
                                maintenance. Please check back after 9PM PT to
                                book an appointment.
                            </Paragraph>
                        </Box>
                    ) : (
                        <AvailableSlots
                            appointmentProfile={appointmentProfile}
                            rescheduledAppointment={rescheduledAppointment}
                            rescheduleAppointmentUuid={
                                rescheduleAppointmentUuid
                            }
                            appointmentSuggestionUuid={
                                appointmentSuggestionUuid
                            }
                            filterOverrides={filterOverrides}
                            scrollRef={scrollRef}
                        />
                    )}
                </Box>
            </Grid>
        </View>
    )
}
