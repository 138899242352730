import {
    Box,
    Flex,
    LayoutGrid,
    Image,
    Text,
    Button,
    Paragraph,
    Card,
    Link,
    Spinner
} from '@asktia/tia-ui'
import { useLayoutEffect } from 'react'
import { useModal } from 'react-modal-hook'
import { BackButton } from 'src/components/Blocks/BackButton'
import { Body } from 'src/components/Blocks/Body'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import { PageHeader } from 'src/components/PageHeader'
import { useAmpli, useHomeMarket, useNavigation } from 'src/hooks'
import { useCreateMessageThreadAndRedirect } from 'src/hooks/useMessageThreads'
import useIntercomHelpArticles from 'src/pages/Chat/NewChat/hooks/useIntercomHelpArticles'
import {
    AwayModal,
    isCareTeamAway
} from 'src/pages/Chat/NewChat/NewChatVariant1'
import { getRefinedDate, isCampTiaDay } from 'src/utils'

const INTERCOM_BASE_URL = 'https://intercom.help/ask-tia'

export const HelpChatPage = () => {
    const {
        fakeChatArticleClicked,
        loadedFakeChat,
        fakeChatNotSatisfiedClicked,
        fakeChatSatisfiedClicked
    } = useAmpli()
    const { navigate } = useNavigation()
    const { homeMarket } = useHomeMarket()
    const { mutate, isLoading } = useCreateMessageThreadAndRedirect()
    const searchParams = new URLSearchParams(window.location.search)
    const { intercomHelpArticles, isLoading: isArticlesLoading } =
        useIntercomHelpArticles(searchParams.get('message') || '')

    const robotAvatar =
        'https://assets.asktia.com/images/pastel_rebrand/logo-white.svg'

    const saveNewThread = () => {
        const message = searchParams.get('message') || ''
        const subject = searchParams.get('subject') || ''
        const label = searchParams.get('label') || ''

        mutate({
            message,
            subject,
            label
        })
    }

    useLayoutEffect(() => {
        if (isArticlesLoading) {
            return
        }

        if (intercomHelpArticles.length > 0) {
            loadedFakeChat()
        } else {
            saveNewThread()
        }
    }, [intercomHelpArticles, isArticlesLoading])

    const onYesClick = () => {
        fakeChatSatisfiedClicked()
        navigate('/chat')
    }

    const [showModal, hideModal] = useModal(
        () => (
            <AwayModal
                onSendMessage={saveNewThread}
                sendingMessage={isLoading}
                hideModal={hideModal}
            />
        ),
        [saveNewThread, isLoading]
    )

    const onNoClick = () => {
        fakeChatNotSatisfiedClicked()

        if (isCareTeamAway(homeMarket?.timezone) || isCampTiaDay(new Date())) {
            showModal()
        } else {
            saveNewThread()
        }
    }

    return (
        <Body>
            <PageHeader
                mobileBack={<BackButton href="/chat" variant="white" />}
            />
            <NavBarHeading titleAlign="left" href="/chat" showBackButton>
                Re: {searchParams.get('subject')}
            </NavBarHeading>

            <LayoutGrid columns="one">
                {isArticlesLoading || intercomHelpArticles.length === 0 ? (
                    <Spinner />
                ) : (
                    <Box
                        sx={{
                            position: 'relative',
                            pl: [5, 0],
                            pr: [5, 0]
                        }}
                    >
                        <Box
                            sx={{
                                overflow: 'auto',
                                borderTop: '1px solid',
                                borderColor: 'cardOutline'
                            }}
                        >
                            <Flex
                                sx={{
                                    flexDirection: 'column',
                                    py: 4,
                                    alignItems: 'flex-start'
                                }}
                            >
                                <Flex sx={{ alignItems: 'center' }}>
                                    <Flex
                                        sx={{
                                            minWidth: 40,
                                            minHeight: 40,
                                            width: 40,
                                            height: 40,
                                            maxWidth: 40,
                                            maxHeight: 40,
                                            bg: 'raspberry',
                                            borderRadius: '100%',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            mr: 2
                                        }}
                                    >
                                        <Image
                                            src={robotAvatar}
                                            sx={{
                                                px: 2,
                                                minWidth: 40,
                                                width: 40,
                                                height: 40
                                            }}
                                        />
                                    </Flex>
                                    <Text sx={{ fontWeight: 700, fontSize: 5 }}>
                                        Tia Clinic
                                    </Text>
                                </Flex>
                                <Box>
                                    <Paragraph
                                        sx={{ pl: 7, fontSize: 4, mb: 4 }}
                                    >
                                        Hi! Tia Whole Health appointment focuses
                                        on a holistic approach to your
                                        well-being, incorporating physical,
                                        mental, and emotional health. For more
                                        information, check out these related
                                        articles:
                                    </Paragraph>

                                    <Card variant="info" sx={{ ml: 7, mb: 4 }}>
                                        <Box
                                            as="ol"
                                            sx={{ pl: 4, color: 'raspberry' }}
                                        >
                                            {intercomHelpArticles
                                                .slice(0, 3)
                                                .map((article, index) => (
                                                    <Box
                                                        as="li"
                                                        sx={{
                                                            mb:
                                                                index >= 2
                                                                    ? undefined
                                                                    : 4
                                                        }}
                                                    >
                                                        <Link
                                                            href={`${INTERCOM_BASE_URL}${article.url}`}
                                                            target="_blank"
                                                            onClick={() =>
                                                                fakeChatArticleClicked(
                                                                    {
                                                                        articleId:
                                                                            article.id,
                                                                        articleUrl:
                                                                            article.url
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            {article.title}
                                                        </Link>
                                                    </Box>
                                                ))}
                                        </Box>
                                    </Card>

                                    <Paragraph
                                        sx={{ pl: 7, fontSize: 4, mb: 4 }}
                                    >
                                        If you have any other questions, feel
                                        free to ask!
                                    </Paragraph>

                                    <Paragraph
                                        sx={{
                                            pl: 7,
                                            fontSize: 4,
                                            mb: 4,
                                            fontWeight: 700
                                        }}
                                    >
                                        Are you satisfied with the result?
                                    </Paragraph>
                                    <Paragraph
                                        sx={{
                                            pl: 7,
                                            fontSize: 4,
                                            mb: 4
                                        }}
                                    >
                                        If you select "No," someone from our
                                        team will contact you shortly.
                                    </Paragraph>

                                    <Flex
                                        sx={{
                                            pl: 7,
                                            flexWrap: 'wrap',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Button
                                            sx={{
                                                width: ['100%', '48%'],
                                                backgroundColor: 'transparent',
                                                color: 'darkSecondaryButton',
                                                border: '1px solid',
                                                borderColor:
                                                    'darkSecondaryButton'
                                            }}
                                            onClick={onYesClick}
                                        >
                                            Yes, satisfied
                                        </Button>
                                        <Button
                                            sx={{
                                                width: ['100%', '48%'],
                                                backgroundColor: 'transparent',
                                                color: 'darkSecondaryButton',
                                                border: '1px solid',
                                                borderColor:
                                                    'darkSecondaryButton'
                                            }}
                                            onClick={onNoClick}
                                            disabled={isLoading}
                                        >
                                            No, keep chat open
                                        </Button>
                                    </Flex>

                                    <Text
                                        variant="supportingInfo"
                                        sx={{ fontSize: 0, pl: 7 }}
                                    >
                                        {getRefinedDate(
                                            searchParams.get('created') ||
                                                new Date()?.toISOString()
                                        )}
                                    </Text>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                )}
            </LayoutGrid>
        </Body>
    )
}
