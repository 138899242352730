import Cookies from 'js-cookie'
import { useState } from 'react'
import { useQuery } from 'react-query'

type IntercomHelpArticle = {
    id: string
    summary: string
    title: string
    url: string
}

async function fetchIntercomHelpArticles(searchPhrase: string) {
    const formData = new FormData()

    formData.append('app_id', 'mnsq6i6j')
    formData.append('platform', 'web')

    const userData = JSON.stringify({
        anonymous_id: Cookies.get('intercom-id-mnsq6i6j')
    })
    formData.append('user_data', userData)
    formData.append('phrase', searchPhrase)

    try {
        const res = await fetch(
            'https://api-iam.intercom.io/messenger/web/articles/search',
            {
                method: 'POST',
                body: formData,
                credentials: 'same-origin'
            }
        )

        const data = await res.json()

        return data.articles && data.articles.length > 0 ? data.articles : []
    } catch (e) {
        return []
    }
}

export function useIntercomHelpArticles(searchPhrase: string) {
    const query = useQuery<IntercomHelpArticle[]>(
        ['intercom-help-articles', searchPhrase],
        () => fetchIntercomHelpArticles(searchPhrase)
    )

    return {
        isLoading: query.isLoading,
        intercomHelpArticles: query.data || []
    }
}

export default useIntercomHelpArticles
