import { FC } from 'react'
import { Card, Text, Link, Box } from '@asktia/tia-ui'
import { useAmpli } from 'src/hooks'

export type HelpArticle = {
    label: string
    url: string
}

const HelpArticleLink = ({ article }: { article: HelpArticle }) => {
    const ampli = useAmpli()

    return (
        <Link
            target="_blank"
            href={article.url}
            onClick={() => ampli.chatHelpArticleClicked()}
        >
            {article.label}
        </Link>
    )
}

export const HelpArticles: FC<{
    description?: string
    helpArticles: HelpArticle[]
    moreHelpLink?: { label: string; url: string }
}> = ({ description, helpArticles, moreHelpLink }) => {
    const ARTICLE_LIMIT = 3
    const topArticles = helpArticles.slice(0, ARTICLE_LIMIT)

    return (
        <Card variant="info" sx={{ my: 4 }}>
            <Text color="putty">
                {description ||
                    'Hi there! Here are some helpful articles we think might answer your question:'}
            </Text>

            <Box
                as="ol"
                sx={{ paddingInlineStart: 22, marginTop: 4, marginBottom: 4 }}
            >
                {topArticles.map(article => (
                    <Text
                        sx={{ marginBottom: 4 }}
                        variant="supportingInfo"
                        as="li"
                    >
                        <HelpArticleLink article={article} />
                    </Text>
                ))}
            </Box>

            <Box>
                <Text variant="supportingInfo">
                    {moreHelpLink ? (
                        <Link target="_blank" href={moreHelpLink.url}>
                            {moreHelpLink.label}
                        </Link>
                    ) : (
                        <Text>
                            Still have questions? Check out our{' '}
                            <Link
                                target="_blank"
                                href="https://intercom.help/ask-tia/en/collections/2025281-for-members"
                            >
                                help center
                            </Link>{' '}
                            for more info!
                        </Text>
                    )}
                </Text>
            </Box>
        </Card>
    )
}
