import { useMutation, useQuery, useQueryClient } from 'react-query'
import { fetcher } from 'src/fetcher'
import { Asserter, CodeableConcept, VerificationStatus } from '../../types'
import { BASE_API_URL } from 'src/globals'
import { useUserInfo } from 'src/hooks'
import { GeneticMutation } from 'src/flows/questionnaires/Uhr/pages/MedicalConditions/GeneticMutationsTypeahead'
import logger from 'src/logger'

export type PatientGeneticMutation = {
    uuid: string
    geneticMutationId: number
    code: CodeableConcept
    display: string
    patientId: number
    verificationStatus: VerificationStatus
    createdAt: Date
    updatedAt: Date
}

type PatientGeneticMutationAPI = {
    geneticMutations: PatientGeneticMutation[]
    latestPatientGeneticMutationConfirmation?: PatientGeneticMutationConfirmation
    emptyReason?: 'notasked' | 'nilknown'
}

type PatientGeneticMutationConfirmation = {
    patientId: number
    createdAt: Date
    lastConfirmedAt: Date
}

export interface IGeneticMutationConfirmation {
    patientId: number
}

const submitConfirmation = async ({
    patientId
}: IGeneticMutationConfirmation) => {
    return (
        fetcher(
            `${BASE_API_URL}/uhr-api/conditions/latest-patient-genetic-mutation-confirmations`,
            JSON.stringify({ patientId }),
            'POST'
        ),
        {
            retry: false,
            customErrorHandler: (res: Response) => {
                res.text().then(body => {
                    logger.info(
                        `ACI Response - submitConfirmation - latest-patient-genetic-mutation-confirmations`,
                        {
                            patientId,
                            status: res.status,
                            errorBody: body
                        }
                    )
                })

                return new Error('Server returned not-ok')
            }
        }
    )
}

export interface IPatchGeneticMutation {
    uuid: string
    verificationStatus: VerificationStatus
}

const patchGeneticMutation = async ({
    uuid,
    verificationStatus
}: IPatchGeneticMutation) =>
    fetcher(
        `${BASE_API_URL}/uhr-api/conditions/patient-owned-genetic-mutations/${uuid}`,
        JSON.stringify({ verificationStatus }),
        'PATCH',
        {
            customErrorHandler: (res: Response) => {
                res.text().then(body => {
                    logger.info(
                        `ACI Response - patchGeneticMutation - patient-owned-genetic-mutations`,
                        {
                            uuid,
                            verificationStatus,
                            status: res.status,
                            errorBody: body
                        }
                    )
                })

                return new Error('Server returned not-ok')
            }
        }
    )

async function fetchMutations(
    patientId: number
): Promise<PatientGeneticMutationAPI> {
    const data: PatientGeneticMutationAPI = await fetcher(
        `${BASE_API_URL}/uhr-api/conditions/patient-owned-genetic-mutations?patientId=${patientId}`,
        undefined,
        undefined,
        {
            customErrorHandler: (res: Response) => {
                res.text().then(body => {
                    logger.info(
                        `ACI Response - fetchMutations - patient-owned-genetic-mutations`,
                        {
                            patientId,
                            status: res.status,
                            errorBody: body
                        }
                    )
                })

                return new Error('Server returned not-ok')
            }
        }
    )

    // convert dates from string to Date
    data.geneticMutations = data.geneticMutations.map(d => ({
        ...d,
        createdAt: new Date(d.createdAt),
        updatedAt: new Date(d.updatedAt)
    }))

    if (data.latestPatientGeneticMutationConfirmation) {
        data.latestPatientGeneticMutationConfirmation.createdAt = new Date(
            data.latestPatientGeneticMutationConfirmation.createdAt
        )
        data.latestPatientGeneticMutationConfirmation.lastConfirmedAt =
            new Date(
                data.latestPatientGeneticMutationConfirmation.lastConfirmedAt
            )
    }

    return data
}

type GeneticMutationPostBody = {
    geneticMutationId: number
    patientId: number
    asserterCategory: Asserter
    verificationStatus: VerificationStatus
}

const postGeneticMutation = async (geneticMutation: GeneticMutationPostBody) =>
    fetcher(
        `${BASE_API_URL}/uhr-api/conditions/patient-owned-genetic-mutations`,
        JSON.stringify(geneticMutation),
        'POST',
        {
            customErrorHandler: (res: Response) => {
                res.text().then(body => {
                    logger.info(
                        `ACI Response - postGeneticMutation - patient-owned-genetic-mutations`,
                        {
                            geneticMutation,
                            status: res.status,
                            errorBody: body
                        }
                    )
                })

                return new Error('Server returned not-ok')
            }
        }
    )

export function useFetchPatientGeneticMutations() {
    const queryKey = 'patient-genetic-mutations'
    const { user, isLoading: userIsLoading } = useUserInfo()
    const { data, isLoading: isGeneticMutationsLoading } =
        useQuery<PatientGeneticMutationAPI>(
            [queryKey, user?.id],
            () => fetchMutations(user!.id),
            { enabled: !!user }
        )
    return {
        data,
        isLoading: isGeneticMutationsLoading || userIsLoading
    }
}

export function useGeneticMutation() {
    const queryKey = 'patient-genetic-mutations'
    const { user, isLoading: userIsLoading } = useUserInfo()
    const queryClient = useQueryClient()
    const { data, isLoading } = useQuery<PatientGeneticMutationAPI>(
        [queryKey, user?.id],
        () => fetchMutations(user!.id),
        { enabled: !!user }
    )
    const {
        mutateAsync: submitConfirmationMutation,
        isLoading: isSubmitConfirmationLoading
    } = useMutation(submitConfirmation)

    const { mutateAsync: patchMutation, isLoading: isPatchLoading } =
        useMutation(patchGeneticMutation, {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKey)
            }
        })

    const { mutateAsync: createMutation } = useMutation(postGeneticMutation, {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKey)
        }
    })

    const create = async (geneticMutation: GeneticMutation) => {
        if (user?.id) {
            const geneticMutationPayload = {
                patientId: user.id,
                asserterCategory: 'patient' as Asserter,
                verificationStatus: 'unconfirmed' as VerificationStatus,
                geneticMutationId: geneticMutation.id
            }
            return createMutation(geneticMutationPayload)
        }
    }

    return {
        isQuerying: userIsLoading || isLoading,
        isMutating: isSubmitConfirmationLoading || isPatchLoading,
        data: data || { geneticMutations: [] },
        submitConfirmation: submitConfirmationMutation,
        update: patchMutation,
        create
    }
}
