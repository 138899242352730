import { Box, Form, Heading, LayoutGrid, Radio, Spinner } from '@asktia/tia-ui'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { BookingCardDetails } from 'src/flows/AppointmentBooking/BookingCard'
import { FooterButton } from 'src/components/Blocks'
import { Show404 } from 'src/flows/404'
import { useContactWithCareTeam } from 'src/hooks/useContactWithCareTeam'
import { PreBookingQuestionOption } from 'src/types'
import { useBookingFlow } from '../useBookingFlow'
import { useAmpli } from 'src/hooks'
import { get, omitBy } from 'lodash'
import { View } from 'src/components/Blocks/View'
import Markdown from 'src/components/Markdown'

const PreBookingQuestion = (props: PreBookingQuestionOption) => {
    return (
        <Box sx={{ mb: 4 }}>
            <Radio
                sx={{
                    'lineHeight': 'inherit',
                    'alignItems': 'center',
                    'mb': 1,
                    '& > div': { flexShrink: 0 }
                }}
                name="bookingAction"
                key={props.action}
                value={props.action}
                pretty
            >
                {props.optionLabel}
            </Radio>
            {props.optionDescription && (
                <Markdown
                    text={props.optionDescription}
                    sx={{
                        fontSize: 3,
                        color: 'supportText',
                        pl: '56px'
                    }}
                    anchorSx={{ fontSize: 3 }}
                />
            )}
        </Box>
    )
}

function useBookingQuestions() {
    const contactWithCareTeam = useContactWithCareTeam({
        subject: 'Consult',
        message: 'I need to talk with a Care Coordinator'
    })
    const { appointmentProfile, isLoading, navigateToNextStep } =
        useBookingFlow()
    const formMethods = useForm()
    const { preQuestionSelected, preQuestionSubmitted } = useAmpli()

    const selectedAction = formMethods.watch('bookingAction')
    const selectedOption = appointmentProfile?.preBookingQuestion?.options.find(
        op => op.action === selectedAction
    )

    let question = ''
    if (appointmentProfile && appointmentProfile.preBookingQuestion?.question) {
        question = appointmentProfile.preBookingQuestion?.question
    }

    useEffect(() => {
        const response = get(selectedOption, 'optionLabel', '')
        // On first render the response will be undefined
        if (question && response) {
            preQuestionSelected(question, response)
        }
    }, [question, selectedOption, preQuestionSelected])

    if (!isLoading && !appointmentProfile?.preBookingQuestion) {
        navigateToNextStep()
        // Keep showing loading spinner while navigation happens
        return {
            onSubmit,
            formMethods,
            isLoading: true
        }
    }

    function onSubmit() {
        if (!selectedOption) {
            // shouldn't happen, just in case :)
            return null
        }

        preQuestionSubmitted(question, selectedOption.optionLabel)
        if (selectedOption.action === 'chat_with_care_team') {
            // override defaults only if necessary
            const contactProps = omitBy(
                {
                    subject: selectedOption.chatSubject,
                    message: selectedOption.chatMessage,
                    label: selectedOption.chatLabel
                },
                v => !v
            )
            contactWithCareTeam(contactProps)
            return
        }

        if (selectedOption.action === 'book-other') {
            window.location.href = `/book/${selectedOption.bookOtherProfileLabel}`
            return
        }

        navigateToNextStep(false, { fromPrevStep: true })
    }

    return {
        isLoading,
        appointmentProfile,
        navigateToNextStep,
        selectedOption,
        formMethods,
        onSubmit
    }
}

export const BookingPreQuestions = () => {
    const {
        isLoading,
        appointmentProfile,
        selectedOption,
        formMethods,
        onSubmit
    } = useBookingQuestions()

    if (isLoading) {
        return <Spinner />
    }

    if (!appointmentProfile) {
        return <Show404 redirectToCareCoordinator />
    }

    return (
        <View withFooter>
            <Form useFormMethods={formMethods} onSubmit={onSubmit}>
                <LayoutGrid columns="two">
                    <Box>
                        <BookingCardDetails
                            appointmentProfile={appointmentProfile!}
                            selectedTreatments={[]}
                        />
                    </Box>

                    <Box>
                        <Heading h3>
                            {appointmentProfile.preBookingQuestion?.question}
                        </Heading>

                        <Box sx={{ mb: 5 }}>
                            {appointmentProfile?.preBookingQuestion?.options.map(
                                option => (
                                    <PreBookingQuestion
                                        key={option.action}
                                        {...option}
                                    />
                                )
                            )}
                        </Box>

                        <FooterButton type="submit" disabled={!selectedOption}>
                            {selectedOption?.actionLabel || 'Next'}
                        </FooterButton>
                    </Box>
                </LayoutGrid>
            </Form>
        </View>
    )
}
