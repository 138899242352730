import { useCallback } from 'react'
import { useModal } from 'react-modal-hook'
import { useNavigation } from 'src/hooks/useNavigation'
import { TiaModal, Button } from '@asktia/tia-ui'
import { ModalTitle } from 'src/components/Blocks'
import { useAmpli } from 'src/hooks/useAmpli'
import { useAppointment } from 'src/hooks/useAppointment'
import { useAppointmentCancellationPolicy } from 'src/hooks/useAppointmentCancellationPolicy'
import Markdown from 'src/components/Markdown'

interface UseAppointmentChangeModalProps {
    appointmentId?: string
    isSupportGroup?: boolean
    returnUrl?: string
}

export const useAppointmentChangeModal = ({
    appointmentId,
    isSupportGroup = false,
    returnUrl
}: UseAppointmentChangeModalProps) => {
    const { navigate } = useNavigation()
    const { appointmentUpdateConfirmed } = useAmpli()
    const { isLoading, appointment } = useAppointment(appointmentId)
    const { appointmentCancellationPolicy } = useAppointmentCancellationPolicy(
        appointment?.appointmentProfileUuid
    )
    const onConfirm = useCallback(() => {
        if (appointment) {
            appointmentUpdateConfirmed(appointment)
        }

        if (isSupportGroup) {
            navigate(`/change-appointment/${appointmentId}/cancel`)
        } else {
            navigate(
                `/change-appointment/${appointmentId}${
                    returnUrl ? `?returnUrl=${returnUrl}` : ''
                }`
            )
        }
    }, [appointmentId, appointment])

    const cancellationCopy =
        appointmentCancellationPolicy?.cancellationCopy || ''

    const [showModal, hideModal] = useModal(
        () => (
            <TiaModal>
                <ModalTitle title="Please note:" hideModal={hideModal} />
                <Markdown
                    text={cancellationCopy}
                    sx={{ fontSize: 4, mb: 6 }}
                ></Markdown>
                <Button
                    onClick={onConfirm}
                    sx={{ mb: 4 }}
                    disabled={isLoading}
                    fullWidth
                >
                    Yes, I’ll pay the fee
                </Button>
                <Button onClick={hideModal} fullWidth>
                    No, I’ll keep my appointment
                </Button>
            </TiaModal>
        ),
        [appointmentId, appointment, cancellationCopy]
    )

    return { showModal, hideModal }
}
