import {
    Flex,
    Card,
    Whisper,
    Box,
    ThemeStyleObject,
    Button,
    Link,
    Text,
    Heading,
    TiaModal
} from '@asktia/tia-ui'
import * as calendarLink from 'calendar-link'
import { differenceInHours } from 'date-fns'
import { ModalTitle } from 'src/components/Blocks'
import {
    AppointmentWithLocationDetails,
    AppointmentCardVariant,
    AppointmentUIStatus,
    Appointment
} from 'src/types'
import { AppointmentProfileCard } from 'src/components/AppointmentProfileCard'
import {
    CalendarExportType,
    VirtualInstructions
} from 'src/flows/AppointmentBooking/BookingCard'
import { AppointmentZoomLink } from 'src/components/AppointmentZoomLink'
import { useAppointmentStatus } from 'src/hooks/useAppointmentStatus'
import { useAmpli, useNavigation } from 'src/hooks'
import { useAppointmentChangeModal } from 'src/flows/AppointmentChange/useAppointmentChangeModal'
import { useAppointmentCancellationPolicy } from 'src/hooks/useAppointmentCancellationPolicy'
import { getDisplayTimestamp } from 'src/utils'
import { useModal } from 'react-modal-hook'
import { capitalize } from 'lodash'

const AppointmentStatusButton = ({
    status,
    onClick,
    sx
}: {
    status: AppointmentUIStatus
    onClick: Function
    sx?: ThemeStyleObject
}) => {
    let copy: string
    let isCheckInNow = false

    switch (status) {
        case 'appointment_ready':
            copy = 'Start appointment'
            break

        case 'check_in_completed':
            copy = "You're checked in!"
            break

        case 'check_in_ready':
            copy = 'Check in now!'
            isCheckInNow = true
            break

        case 'uhr_completed':
            copy = 'View appointment'
            break

        case 'finding_provider':
            copy = 'Enter waiting room'
            break

        case 'check_in_not_ready':
        default:
            copy = 'Complete your next steps'
            break
    }

    return (
        <Button onClick={() => onClick(copy, isCheckInNow)} sx={sx} fullWidth>
            {copy}
        </Button>
    )
}

const CancelLink = (props: {
    appointment: Appointment
    appointmentStatus: AppointmentUIStatus
}) => {
    const isSupportGroup = props.appointment?.isSupportGroup === true

    const { navigate } = useNavigation()
    const { cancelOrChangeAppointmentClicked } = useAmpli()
    const { showModal } = useAppointmentChangeModal({
        appointmentId: props.appointment.id,
        isSupportGroup
    })
    const { appointmentCancellationPolicy } = useAppointmentCancellationPolicy(
        props.appointment.appointmentProfileUuid
    )

    const remainingHoursToAppointment = differenceInHours(
        props.appointment?.scheduledTime,
        new Date()
    )

    function onClick() {
        cancelOrChangeAppointmentClicked(props.appointment)

        if (!appointmentCancellationPolicy) {
            // no-op if we don't know the rules yet
            return
        }

        if (
            remainingHoursToAppointment <
            appointmentCancellationPolicy.cutoffHours
        ) {
            showModal()
        } else {
            if (isSupportGroup) {
                navigate(`/change-appointment/${props.appointment.id}/cancel`)
            } else {
                navigate(
                    `/change-appointment/${props.appointment.id}?returnUrl=/r/book-appointment`
                )
            }
        }
    }

    return (
        <Box
            sx={{
                mt: 4,
                textAlign: 'center'
            }}
        >
            <Link
                onClick={onClick}
                sx={{
                    color: 'supportText',
                    fontSize: 0,
                    cursor: 'pointer'
                }}
            >
                {isSupportGroup
                    ? 'Cancel this appointment'
                    : 'Cancel or change this appointment'}
            </Link>
        </Box>
    )
}

const HomepageButtons = ({
    appointment
}: {
    appointment: AppointmentWithLocationDetails
}) => {
    // updates live while you sit there
    const { canStart, appointmentStatus } = useAppointmentStatus(appointment)
    const { navigate } = useNavigation()
    const { checkInNowClicked, upcomingAppointmentCardClicked } = useAmpli()

    const handleCtaClick = (buttonText: string, isCheckInNow = false) => {
        if (isCheckInNow) {
            checkInNowClicked(appointment)
        }

        upcomingAppointmentCardClicked(appointment, buttonText)

        navigate(`/appointment/${appointment.id}`)
    }

    if (appointmentStatus === 'no_show') {
        return null
    }

    if (canStart && appointment.isVirtual) {
        return (
            <>
                <AppointmentZoomLink appointment={appointment} />
                <CancelLink
                    appointment={appointment}
                    appointmentStatus={appointmentStatus}
                />
            </>
        )
    }

    return (
        <>
            <AppointmentStatusButton
                status={appointmentStatus}
                onClick={handleCtaClick}
            />
            <CancelLink
                appointment={appointment}
                appointmentStatus={appointmentStatus}
            />
        </>
    )
}

type CalendarLinkTypes = 'google' | 'ics' | 'outlook'

const AddToCalModal = (props: {
    hideModal: Function
    calProps: calendarLink.CalendarEvent
}) => {
    const { addToCalendarClicked } = useAmpli()
    const onClickHandler = (type: CalendarLinkTypes) => () => {
        addToCalendarClicked(type)
        window.open(calendarLink[type](props.calProps), '_blank')
        props.hideModal()
    }

    return (
        <TiaModal>
            <ModalTitle title="Add to calendar" hideModal={props.hideModal} />

            <Text sx={{ fontSize: 3 }}>
                For your privacy, it will show up as "Tia Appointment"
                <br />
                Select your preferred calendar:
            </Text>
            <Box
                sx={{
                    py: 4,
                    borderBottomRightRadius: 2,
                    borderBottomLeftRadius: 2
                }}
            >
                <Button
                    onClick={onClickHandler('google')}
                    sx={{ mt: 4, minWidth: '100%' }}
                >
                    Google
                </Button>
                <Button
                    onClick={onClickHandler('ics')}
                    sx={{ mt: 4, minWidth: '100%' }}
                >
                    iCal
                </Button>
                <Button
                    onClick={onClickHandler('outlook')}
                    sx={{ mt: 4, minWidth: '100%' }}
                >
                    Outlook
                </Button>
            </Box>
        </TiaModal>
    )
}

const AppointmentCardDetails = ({
    variant = 'booking',
    appointmentId,
    calendarExport,
    appointmentStatus,
    appointment
}: {
    variant?: AppointmentCardVariant
    appointmentId?: string
    calendarExport?: CalendarExportType
    appointmentStatus?: AppointmentUIStatus
    appointment: AppointmentWithLocationDetails
}) => {
    const startTimeDisplay = getDisplayTimestamp(
        appointment.scheduledTime,
        appointment.locationDetails.timezone
    )
    const locationLine1 = `${appointment.locationDetails.clinic.address1} ${
        appointment.locationDetails.clinic.address2 || ''
    }`
    const locationLine2 = `${appointment.locationDetails.clinic.city}, ${appointment.locationDetails.clinic.state} ${appointment.locationDetails.clinic.postalCode}`
    const locationPhysicalAddress = `${locationLine1}, ${locationLine2}`
    const locationVirtualAddress = `${appointment.locationDetails.virtualAddress}${appointmentId}`
    const [showBookingModal, hideBookingModal] = useModal(
        () => (
            <AddToCalModal
                hideModal={() => {
                    hideBookingModal()
                }}
                calProps={{
                    title: 'Tia Appointment',
                    start: appointment.scheduledTime,
                    duration: [appointment.patientDuration, 'minutes'],
                    location: appointment.isVirtual
                        ? locationVirtualAddress
                        : locationPhysicalAddress
                }}
            />
        ),
        []
    )

    const calendarExportProps =
        appointmentId && calendarExport === 'in-date'
            ? {
                  sx: { textDecoration: 'underline', cursor: 'pointer' },
                  onClick: showBookingModal
              }
            : {}

    return (
        <Box>
            <Heading h4 sx={{ mb: 2 }}>
                <Text {...calendarExportProps}>
                    {`${startTimeDisplay.day}, ${startTimeDisplay.time} ${startTimeDisplay.timezone}`}
                    {appointment.cadence &&
                        `, ${capitalize(appointment.cadence)}`}
                </Text>
            </Heading>
            {appointment.locationDetails.modality === 'clinic' ? (
                <Box sx={{ fontSize: 0 }}>
                    <Text sx={{ mb: 1 }} as="div">
                        {appointment.locationDetails.clinic.name}
                    </Text>
                    <Text sx={{ color: 'supportText' }}>
                        {locationLine1}
                        <br />
                        {locationLine2}
                    </Text>
                </Box>
            ) : (
                <VirtualInstructions
                    slot={{
                        locationDetails: appointment.locationDetails,
                        startTime: appointment.scheduledTime
                    }}
                    variant={variant}
                    appointmentStatus={appointmentStatus}
                    appointment={appointment}
                />
            )}
            {appointmentId && calendarExport === 'button' && (
                <Button onClick={showBookingModal} sx={{ mt: 4 }}>
                    Add to Calendar
                </Button>
            )}
        </Box>
    )
}

export const AppointmentCard = (props: {
    appointment: AppointmentWithLocationDetails
    sx?: ThemeStyleObject
    // TODO: add variant for appointment screen, potentially merge BookingCard
    variant?: AppointmentCardVariant
}) => {
    const { appointment, variant } = props
    const location = appointment.isVirtual ? 'virtual' : 'clinic'
    const { appointmentStatus } = useAppointmentStatus(props.appointment)

    return (
        <Flex
            sx={{
                ...(props.sx || {}),
                flexDirection: 'column'
            }}
        >
            <AppointmentProfileCard
                appointmentProfile={{
                    label: appointment.label,
                    locations: [location],
                    imageUrl: appointment.imageUrl
                }}
                hideDescription
                flatBottom
                provider={
                    appointment.providerName && appointment.providerGender
                        ? {
                              name: appointment.providerName,
                              gender: appointment.providerGender,
                              pronoun: appointment.providerPronoun
                          }
                        : undefined
                }
            />
            <Card
                sx={{
                    border: 'none',
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    flex: 1
                }}
            >
                <Box sx={{ flex: 1 }}>
                    <AppointmentCardDetails
                        appointmentId={appointment.id}
                        variant={variant}
                        calendarExport={
                            variant === 'homepage' ? 'in-date' : 'button'
                        }
                        appointmentStatus={appointmentStatus}
                        appointment={appointment}
                    />
                    {appointmentStatus === 'no_show' &&
                    variant === 'homepage' ? (
                        <Box sx={{ mt: 4 }}>
                            <Text sx={{ fontSize: 0, color: 'supportText' }}>
                                In accordance with our no-show policy, your
                                appointment has been cancelled.
                            </Text>
                        </Box>
                    ) : null}
                </Box>
                {variant === 'homepage' ? (
                    <Box sx={{ mt: 4 }}>
                        <HomepageButtons appointment={appointment} />
                    </Box>
                ) : null}
            </Card>
        </Flex>
    )
}

export const AppointmentCardSkeleton = (props: { sx?: ThemeStyleObject }) => (
    <Flex sx={{ ...(props.sx || {}), flexDirection: 'column' }}>
        <Card
            sx={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
            }}
        >
            <Flex sx={{ flexWrap: 'wrap' }}>
                <Whisper
                    variant="circle"
                    sx={{ height: 56, width: 56, mr: 4 }}
                />
                <Flex sx={{ flexDirection: 'column', flex: 1 }}>
                    <Whisper sx={{ mb: 1, height: 5, width: 9 }} />
                    <Whisper sx={{ mb: 1, height: 4, width: 7 }} />
                </Flex>
            </Flex>
        </Card>
        <Card
            sx={{
                borderTop: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                display: 'flex',
                flexDirection: 'column',
                flex: 1
            }}
        >
            <Whisper sx={{ mb: 2, height: 5, width: 10 }} />
            <Whisper sx={{ mb: 1, height: 4, width: 9 }} />
        </Card>
    </Flex>
)
