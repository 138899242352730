/* eslint-disable no-case-declarations */

import { Box, Button, Container, Flex, Link } from '@asktia/tia-ui'
import { addDays, subDays, startOfToday, format, isSameDay } from 'date-fns'
import {
    MouseEvent,
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import {
    DateFilterModal,
    GenderFilterModal,
    LocationFilterModal,
    CadenceFilterModal
} from './FilterModals'
import {
    AppointmentProfile,
    AppointmentCadenceType,
    Appointment
} from 'src/types'
import { APPOINTMENT_PROFILE_NAMES } from 'src/globals'
import { ProviderFilterModal } from './FilterModals/ProviderFilterModal'
import { FilterPill } from './FilterPill'
import { isEqual, isNil } from 'lodash'
import { getFiltersFromUrl } from 'src/flows/AppointmentBooking/useAvailableSlots'
import { useAppointmentSuggestion } from 'src/flows/AppointmentBooking/useAppointmentSuggestion'
import { useParams } from 'react-router'
import { useAppointmentProfile } from 'src/flows/AppointmentBooking/useAppointmentProfile'
import { useAppointment } from 'src/hooks/useAppointment'
import { useOfferingDetails } from 'src/flows/AppointmentBooking/useOfferingDetails'

export type FilterModalProps<T> = {
    hideModal: () => void
    onSelected: (values: T) => void
    onClearSelections: () => void
    initialValue?: T
    appointmentProfile: AppointmentProfile
    maxDate?: Date
    minDate?: Date
    rescheduledAppointment?: Appointment
}

// To add a new filter, copy an object in this list
// Each filter is identified by a unique key
// Key will be used as field name when sending data to backend
const DEFAULT_FILTERS = {
    startDate: {
        value: startOfToday(), // current value
        defaultValue: startOfToday(),
        isFiltering: false, // is changed by user
        label: 'Date', // label for button
        // contents of the modal when it opens
        // gets closeModal and selectValue methods
        modal: (props: FilterModalProps<Date>) => (
            <DateFilterModal {...props} />
        ),
        // decide if filter should be visible
        isEnabled: () => true
    },
    providerGender: {
        value: [],
        defaultValue: [],
        isFiltering: false,
        label: 'Gender',
        modal: (props: FilterModalProps<string[]>) => (
            <GenderFilterModal {...props} />
        ),
        isEnabled: (appointmentProfile: AppointmentProfile) =>
            appointmentProfile.allowProviderGenderSelection
    },
    providerUuids: {
        value: [],
        defaultValue: [],
        isFiltering: false,
        label: 'Provider',
        modal: (props: FilterModalProps<string[]>) => (
            <ProviderFilterModal {...props} />
        ),
        isEnabled: (appointmentProfile: AppointmentProfile) =>
            appointmentProfile.allowSpecificProviderSelection
    },
    clinicUuids: {
        value: [],
        defaultValue: [],
        isFiltering: false,
        label: 'Location',
        modal: (props: FilterModalProps<string[]>) => (
            <LocationFilterModal {...props} />
        ),
        isEnabled: (appointmentProfile: AppointmentProfile) =>
            appointmentProfile.clinicsWithOffering.length > 1
    },
    cadence: {
        value: 'weekly',
        defaultValue: 'weekly',
        isFiltering: false,
        label: 'Frequency',
        modal: (props: FilterModalProps<AppointmentCadenceType[]>) => (
            <CadenceFilterModal {...props} />
        ),
        isEnabled: (appointmentProfile: AppointmentProfile) =>
            appointmentProfile.allowFrequencySelection
    }
}

// Collect filter keys into a type to be used for type safety
type filterKeys = keyof typeof DEFAULT_FILTERS

const getAmountOfFilters = (
    filterKey: string,
    value: string | string[] | Date
) => {
    let amountOfFilters = ''

    if (value && Array.isArray(value)) {
        amountOfFilters = value.length > 0 ? ` (${value.length})` : ''
    } else {
        amountOfFilters = value ? ' (1)' : ''
    }

    if (filterKey === 'cadence' && value === 'weekly') {
        // Default frequency
        amountOfFilters = ''
    }

    if (
        filterKey === 'startDate' &&
        isSameDay(new Date(value as string), startOfToday())
    ) {
        amountOfFilters = ''
    }

    return amountOfFilters
}

const getDefaultFilters = (values: any) => {
    const filters = { ...DEFAULT_FILTERS }
    Object.getOwnPropertyNames(filters).map(key => {
        const filter = filters as any
        filter[key as filterKeys] = { ...filters[key as filterKeys] }
    })

    const possibleFilterKeys = Object.getOwnPropertyNames(DEFAULT_FILTERS)

    Object.getOwnPropertyNames(values).forEach(key => {
        const filteredKey = key as filterKeys
        let value = values[filteredKey]

        if (!possibleFilterKeys.some(possibleKey => possibleKey === key)) {
            return
        }

        const amountOfFilters = getAmountOfFilters(filteredKey, value)

        if (filteredKey === 'startDate') {
            const dateValue = new Date(
                value || DEFAULT_FILTERS[filteredKey].defaultValue
            )
            filters[filteredKey].value = dateValue
            value = dateValue

            filters[filteredKey].isFiltering = Array.isArray(value)
                ? value.length > 0
                : !isSameDay(value, DEFAULT_FILTERS[filteredKey].value)
        } else {
            filters[filteredKey].value =
                value || DEFAULT_FILTERS[filteredKey].defaultValue

            filters[filteredKey].isFiltering = Array.isArray(value)
                ? value.length > 0
                : !isEqual(value, DEFAULT_FILTERS[filteredKey].value)
        }

        filters[
            filteredKey
        ].label = `${DEFAULT_FILTERS[filteredKey].label}${amountOfFilters}`
    })

    return filters
}

const useFilterValuesFromLocation = () => {
    const filtersStr = JSON.stringify(getFiltersFromUrl())
    const filtersFromLocation = useMemo(() => {
        return JSON.parse(filtersStr)
    }, [filtersStr])

    return filtersFromLocation
}

const useOldStartDates = (appointmentProfile?: AppointmentProfile) => {
    const OLD_START_DATES_KEY = `OLD_START_DATES_${appointmentProfile?.appointmentProfileUuid}`
    const oldStartDates = JSON.parse(
        sessionStorage.getItem(OLD_START_DATES_KEY) || '[]'
    )

    const pushOldStartDates = (date: string) => {
        sessionStorage.setItem(
            OLD_START_DATES_KEY,
            JSON.stringify([...oldStartDates, date])
        )
    }

    const popOldStartDates = () => {
        const lastDate = oldStartDates.pop()
        sessionStorage.setItem(
            OLD_START_DATES_KEY,
            JSON.stringify(oldStartDates)
        )
        return lastDate
    }

    const resetOldStartDates = () => {
        sessionStorage.removeItem(OLD_START_DATES_KEY)
    }

    return {
        oldStartDates,
        pushOldStartDates,
        popOldStartDates,
        resetOldStartDates
    }
}

export function useAvailableSlotsFilter() {
    const { appointmentProfileUuid } = useParams<{
        appointmentProfileUuid: string
    }>()
    const [currentModal, setCurrentModal] = useState<filterKeys>('startDate')
    const [currentValue, setCurrentValue] = useState<any>(undefined)
    const [_, setSearchParams] = useSearchParams()

    const values = useFilterValuesFromLocation()

    const filters = getDefaultFilters(values)

    const { appointment: rescheduledAppointment } = useAppointment(
        values.reschedule // Reschedule appt uuid
    )
    const suggestionQuery = useAppointmentSuggestion(
        values.context || rescheduledAppointment?.appointmentSuggestionUuid
    )
    const { profile: appointmentProfile } = useAppointmentProfile(
        appointmentProfileUuid,
        values.context // ApptSuggestionUuid
    )

    const {
        oldStartDates,
        pushOldStartDates,
        popOldStartDates,
        resetOldStartDates
    } = useOldStartDates(appointmentProfile)

    const hasPrevPages = oldStartDates.length > 0

    useEffect(() => {
        if (suggestionQuery.suggestion && isNil(values.loadedSuggestion)) {
            updateValues({
                ...suggestionQuery?.suggestion?.params,
                providers: JSON.stringify(
                    suggestionQuery?.suggestion?.providers.map(x => ({
                        staffUuid: x.uuid,
                        name: x.name
                    }))
                ),
                loadedSuggestion: true
            } as any)
            clearFilter('context')
        }
    }, [suggestionQuery.suggestion, values])

    const hasFilters = useMemo(
        () => Object.values(filters).some(x => x.isFiltering),
        [filters]
    )

    const clearFilters = () => {
        const searchParams = new URLSearchParams(window.location.search)
        const reschedule = searchParams.get('reschedule')
        const contextFromQueryParams = searchParams.get('context')
        const backTo = searchParams.get('backTo')

        const newSearchParams: any = {}

        if (!isNil(reschedule)) {
            newSearchParams.reschedule = reschedule
        }

        if (!isNil(backTo)) {
            newSearchParams.backTo = backTo
        }

        if (!isNil(contextFromQueryParams)) {
            newSearchParams.context = contextFromQueryParams
        }

        resetOldStartDates()
        setSearchParams(newSearchParams, {
            preventScrollReset: true
        })
    }

    const clearFilter = (filterKey: filterKeys | 'context') => {
        const searchParams = new URLSearchParams(window.location.search)
        searchParams.delete(filterKey)

        resetOldStartDates()
        setSearchParams(Object.fromEntries(searchParams), {
            preventScrollReset: true
        })
    }

    const updateValue = (
        filterKey: filterKeys,
        value: string | Date,
        clearOldDates = true
    ) => {
        const searchParams = new URLSearchParams(window.location.search)
        const currentSearchParam: any = getFiltersFromUrl()

        if (clearOldDates) {
            resetOldStartDates()
        }

        if (currentSearchParam[filterKey] !== value) {
            const strValue = value instanceof Date ? value.toISOString() : value

            searchParams.set(filterKey, strValue)
            setSearchParams(Object.fromEntries(searchParams), {
                preventScrollReset: true
            })
        }
    }

    const updateValues = (values?: { [filterKey: string]: string | Date }) => {
        if (!values) {
            return
        }

        const searchParams = new URLSearchParams(window.location.search)
        const currentSearchParam: any = getFiltersFromUrl()

        const reschedule = searchParams.get('reschedule')
        const contextFromQueryParams = searchParams.get('context')
        const backTo = searchParams.get('backTo')

        const keys = [
            ...Object.getOwnPropertyNames(DEFAULT_FILTERS),
            'loadedSuggestion',
            'providers'
        ]

        for (const key of keys) {
            let value = values[key]
            if (
                key === 'startDate' &&
                (!value || new Date(value) < startOfToday())
            ) {
                value = startOfToday()
            }

            if (!!value && currentSearchParam[key] !== value) {
                const strValue =
                    value instanceof Date ? value.toISOString() : value

                searchParams.set(key, strValue)
            }
        }

        // We should not remove those properties from the query
        if (!isNil(reschedule)) {
            searchParams.set('reschedule', reschedule)
        }

        if (!isNil(backTo)) {
            searchParams.set('backTo', backTo)
        }

        if (!isNil(contextFromQueryParams)) {
            searchParams.set('context', contextFromQueryParams)
        }

        resetOldStartDates()
        setSearchParams(Object.fromEntries(searchParams), {
            preventScrollReset: true
        })
    }

    const nextDateRange = useCallback(
        (_: Date, lastSlotTime: Date) => {
            pushOldStartDates(values.startDate)
            updateValue('startDate', lastSlotTime.toISOString(), false)
        },
        [oldStartDates]
    )

    const prevDateRange = useCallback(() => {
        const lastDate = popOldStartDates()
        updateValue('startDate', lastDate, false)
    }, [oldStartDates])

    // The modal uses currentModal state to choose
    // which modal is rendered based on configuration
    const [showModal, hideModal] = useModal(() => {
        if (appointmentProfile) {
            // appointmentProfile should be defined by now
            // we avoid poluting code with null checks
            let maxDate = addDays(new Date(), 107)
            let minDate
            if (
                (appointmentProfile.name ===
                    APPOINTMENT_PROFILE_NAMES.TALK_THERAPY_INTAKE ||
                    appointmentProfile.name ===
                        APPOINTMENT_PROFILE_NAMES.TALK_THERAPY_PROGRAM ||
                    appointmentProfile.name ===
                        APPOINTMENT_PROFILE_NAMES.TALK_THERAPY_RETURNING) &&
                rescheduledAppointment?.scheduledTime
            ) {
                maxDate = addDays(
                    new Date(rescheduledAppointment?.scheduledTime),
                    5
                )
                const idealMinDate = subDays(
                    rescheduledAppointment?.scheduledTime,
                    5
                )
                const now = new Date()
                minDate = idealMinDate >= now ? idealMinDate : now
            }

            return filters[currentModal].modal({
                hideModal,
                onSelected: (value: Date | string[]) =>
                    updateValue(currentModal, value as any),
                onClearSelections: () => clearFilter(currentModal),
                initialValue: currentValue,
                appointmentProfile,
                maxDate,
                minDate,
                rescheduledAppointment
            })
        } else {
            return <></>
        }
    }, [currentModal, currentValue, appointmentProfile, updateValue])

    // Sets current modal and opens,
    // this is to avoid handling each modal separately
    const openModal = useCallback((filterKey: filterKeys) => {
        setCurrentModal(filterKey)
        setCurrentValue(filters[filterKey].value)
        showModal()
    }, [])

    // If startDate is not present, we need to set it to today
    // If startDate is on the past, we also need to set it to today
    if (!values.startDate || new Date(values.startDate) < startOfToday()) {
        resetOldStartDates()
        values.startDate = startOfToday()
    }

    return useMemo(
        () => ({
            filters,
            filterValues: values,
            hasFilters,
            clearFilters,
            updateValue,
            openModal,
            nextDateRange,
            prevDateRange,
            hasPrevPages
        }),
        [values, filters]
    )
}

export const AvailableSlotsFilter = ({
    appointmentProfile,
    scrollRef,
    rescheduledAppointment
}: {
    appointmentProfile: AppointmentProfile
    scrollRef: MutableRefObject<HTMLDivElement | null>
    rescheduledAppointment?: Appointment
}) => {
    const { offeringDetails } = useOfferingDetails(
        appointmentProfile.appointmentProfileUuid
    )
    const { filters, hasFilters, openModal, updateValue, clearFilters } =
        useAvailableSlotsFilter()

    // Hide disabled filters
    const enabledFilters = Object.entries(filters).filter(([key, filter]) => {
        if (
            (appointmentProfile.name ===
                APPOINTMENT_PROFILE_NAMES.TALK_THERAPY_INTAKE ||
                appointmentProfile.name ===
                    APPOINTMENT_PROFILE_NAMES.TALK_THERAPY_PROGRAM ||
                appointmentProfile.name ===
                    APPOINTMENT_PROFILE_NAMES.TALK_THERAPY_RETURNING) &&
            rescheduledAppointment
        ) {
            return key === 'startDate'
        }

        return filter.isEnabled(appointmentProfile)
    })

    function clearSelections(event: MouseEvent) {
        event.preventDefault()
        clearFilters()
    }

    const capitalizeFirstLetter = (value: string) =>
        value[0].toUpperCase() + value.slice(1)

    const getFilterValue = (name: string, value: string | Date) => {
        switch (name) {
            case 'startDate':
                const currentDate = value as Date
                return format(currentDate, 'MM/dd/yyyy')
            case 'providerUuids':
                const searchParams = new URLSearchParams(window.location.search)
                const providers = JSON.parse(
                    searchParams.get('providers') || '[]'
                )

                const allProviders = [
                    ...(offeringDetails?.clinicStaff || []),
                    ...providers
                ]

                const staff = allProviders.find(
                    staff => staff.staffUuid === value
                )
                return staff?.name
            case 'clinicUuids':
                const clinic = appointmentProfile.clinicsWithOffering.find(
                    x => x.uuid === value
                )
                return clinic?.isVirtual ? 'Virtual' : clinic?.name
        }

        return capitalizeFirstLetter(value as string)
    }

    const onRemoveFilter = (filterKey: string, value: string) => {
        const currentFilter = (filters as any)[filterKey]
        const oldValues = currentFilter?.value
        let valueOverride

        if (oldValues) {
            if (filterKey === 'cadence') {
                if (value === 'bi-weekly') {
                    valueOverride = 'weekly'
                }

                if (value === 'weekly') {
                    valueOverride = 'bi-weekly'
                }
            }

            if (filterKey === 'startDate') {
                valueOverride = undefined
                return updateValue(filterKey, startOfToday().toISOString())
            }

            updateValue(
                filterKey as any,
                valueOverride ||
                    oldValues.filter((oldValue: string) => oldValue !== value)
            )
        }
    }

    return (
        <Container sx={{ px: [0, 0] }}>
            <Box
                sx={{
                    bg: 'raspberry',
                    borderTopLeftRadius: 2,
                    borderTopRightRadius: 2,
                    borderBottomLeftRadius: [0, 2],
                    borderBottomRightRadius: [0, 2]
                }}
                ref={scrollRef}
            >
                {enabledFilters.map(([key, filter]) => (
                    <Button
                        variant="secondaryLight"
                        onClick={() => openModal(key as filterKeys)}
                        key={key}
                        sx={{
                            mr: 2,
                            mb: 2,
                            py: 3,
                            px: 4,
                            width: 'fit-content',
                            borderRadius: '44px',
                            backgroundColor: filter.isFiltering
                                ? '#69153B' // raspberry 20% darker, we don't have this on tia-ui
                                : undefined
                        }}
                    >
                        {filter.label}
                    </Button>
                ))}
                <Flex
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    {
                        <Flex sx={{ gap: 1, alignItems: 'center', mt: 4 }}>
                            {hasFilters && (
                                <Link
                                    sx={{
                                        color: 'white',
                                        lineHeight: 1,
                                        mb: 4
                                    }}
                                    href="#"
                                    onClick={clearSelections}
                                >
                                    Clear filters
                                </Link>
                            )}
                        </Flex>
                    }
                </Flex>

                <Flex sx={{ flexWrap: 'wrap' }}>
                    {Object.getOwnPropertyNames(filters)
                        .filter(
                            filterName =>
                                (filters as any)[filterName].isFiltering
                        )
                        .map(filterName => {
                            const currentFilter = (filters as any)[filterName]

                            if (Array.isArray(currentFilter.value)) {
                                return currentFilter.value.map(
                                    (value: string) => ({
                                        name: filterName,
                                        value: value
                                    })
                                )
                            }

                            return {
                                name: filterName,
                                value: currentFilter.value
                            }
                        })
                        .flat()
                        .map(filter => {
                            return (
                                <FilterPill
                                    filterKey={filter.name}
                                    value={filter.value}
                                    onClose={onRemoveFilter}
                                    sx={{ mr: 2, mb: 2 }}
                                >
                                    {getFilterValue(filter.name, filter.value)}
                                </FilterPill>
                            )
                        })}
                </Flex>
            </Box>
        </Container>
    )
}
