import { Box, Form, Container, Blocks } from '@asktia/tia-ui'
import { FC, useEffect, useLayoutEffect, useState } from 'react'
import { Header } from 'src/components/Blocks'
import { useScrollToTopOnPageLoad } from 'src/hooks'
import { FilterContainers } from './components/FilterContainers'
import { BookingGrid } from './components/BookingGrid'
import {
    ClinicDropdownItem,
    fakeVirtualClinicForDropdown,
    useAppointmentFilters
} from 'src/pages/Appointments/useAppointmentFilters'
import { useOfferings } from 'src/hooks/useOfferings'
import { uniqBy } from 'lodash'
import { AppointmentProfile } from 'src/types'
import { ServiceLinesCarousel } from './components/ServiceLinesCarousel'
import { HidableFilters } from './HideableFilters'
import { useTrackingStuff } from './useTrackingStuff'
import { LoadingScreen } from 'src/pages/Home'
import { useAppointmentSuggestions } from 'src/hooks/useAppointmentSuggestions'
import { OldAppointmentSearch } from './components/OldAppointmentSearch'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'
import { NewFeelingFilter } from 'src/pages/Appointments/components/NewFeelingFilter'
import useDetectScroll from 'src/hooks/useDetectScroll'
import { PaymentWarningBanner } from 'src/components/PaymentWarningBanner'
import { useAvailableSlotsFilter } from 'src/flows/AppointmentBooking/views/BookingTimeSelection/AvailableSlotsFilter'

function getDropdownLocations(
    offerings: AppointmentProfile[]
): ClinicDropdownItem[] {
    const locationsOnGrid =
        uniqBy(
            offerings
                ?.map(x => x.clinicsWithOffering)
                .flat()
                .filter(c => !c.isVirtual)
                // Hidden Flatiron while the clinic is temporarily closed
                .filter(c => c.name !== 'Flatiron'),
            c => c.uuid
        ) || []

    locationsOnGrid.push(fakeVirtualClinicForDropdown)

    return locationsOnGrid.map(c => ({
        label: c.name,
        value: c.name,
        uuid: c.uuid
    }))
}

const AppointmentsGrid: FC<{ offerings: AppointmentProfile[] }> = ({
    offerings
}) => {
    const showPlainSymptomsSearch =
        useAmpliFeatureFlag('plain-symptoms-field-in-appointment-search') ===
        'treatment'

    const { appointmentSuggestions } = useAppointmentSuggestions({
        status: 'pending'
    })

    const {
        onSubmit,
        formMethods,
        filterFunction,
        numActiveFilters,
        resetFilters,
        selectServiceLine
    } = useAppointmentFilters(offerings, appointmentSuggestions)
    const [skipCloseOnScroll, setSkipCloseOnScroll] = useState(true)

    const [showFilters, setShowFilters] = useState(numActiveFilters > 0)

    const [scrollDir, offset, lastOffset] = useDetectScroll({
        axis: 'y'
    })

    const { trackServiceLineBanner } = useTrackingStuff()

    // scrolls to top on URL change
    useScrollToTopOnPageLoad()

    useLayoutEffect(() => {
        setTimeout(() => {
            setSkipCloseOnScroll(false)
        }, 1000)
    }, [])

    const toggleShowFilters = () => {
        setSkipCloseOnScroll(true)
        setShowFilters(oldValue => !oldValue)

        setTimeout(() => {
            setSkipCloseOnScroll(false)
        }, 1000)
    }

    useEffect(() => {
        if (!skipCloseOnScroll && showFilters) {
            setShowFilters(false)
        }
    }, [scrollDir, showFilters, lastOffset, offset])

    return (
        <>
            <Form
                useFormMethods={formMethods}
                onSubmit={onSubmit}
                sx={{ width: '100%', zIndex: 1, position: 'relative' }}
            >
                <FilterContainers>
                    {showPlainSymptomsSearch ? (
                        <NewFeelingFilter
                            formMethods={formMethods}
                            offerings={offerings}
                            numActiveFilters={numActiveFilters}
                            onFilterHamburger={toggleShowFilters}
                        />
                    ) : (
                        <OldAppointmentSearch
                            offerings={offerings}
                            formMethods={formMethods}
                            numActiveFilters={numActiveFilters}
                            onFilterHamburger={toggleShowFilters}
                        />
                    )}
                    <HidableFilters
                        showFilters={showFilters}
                        resetFilters={resetFilters}
                        numActiveFilters={numActiveFilters}
                    />
                </FilterContainers>

                <BookingGrid
                    offerings={offerings}
                    isLoading={false}
                    filterFunction={filterFunction}
                />

                <Box
                    sx={{
                        bg: 'secondaryBackground',
                        position: 'relative',
                        mt: 6,
                        pt: 8,
                        pb: 8,
                        zIndex: -1
                    }}
                >
                    <Blocks.Wave
                        position="top"
                        fill="secondaryBackground"
                        sx={{ backgroundColor: 'mainBackground' }}
                    />
                    <Container>
                        <ServiceLinesCarousel
                            selectServiceLine={(serviceLineName: string) => {
                                trackServiceLineBanner(serviceLineName)
                                setShowFilters(true)
                                selectServiceLine(serviceLineName)
                            }}
                        />
                    </Container>
                </Box>
            </Form>
        </>
    )
}

export const AppointmentsPage: FC = () => {
    const { offerings, isLoading } = useOfferings()

    if (isLoading || !offerings) {
        return <LoadingScreen isLoading />
    }

    return (
        <>
            <Header showBack={false} />
            <PaymentWarningBanner />
            <AppointmentsGrid offerings={offerings} />
        </>
    )
}
