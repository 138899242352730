import { NewChatVariant1 } from './NewChatVariant1'
import { NewChatVariant2 } from './NewChatVariant2'
import { NewChatVariant3 } from './NewChatVariant3'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'
import { Spinner } from '@asktia/tia-ui'
import { Variant } from '@amplitude/experiment-js-client'

export const NewChat = () => {
    // We need the full payload here because while amplitude is connecting to their servers, the payload is undefined.
    // Once it connects or timeouts, though, the payload is an object.
    const chatExperience = useAmpliFeatureFlag(
        'new-chat-experience',
        true
    ) as Variant

    if (chatExperience == null) {
        // This prevents someone from seeing chat variant 1 who will then flicker to variant 2
        // Amplitude will return undefined for a while and eventually return empty object {} if the user
        // is unable to contact its servers
        return <Spinner />
    }

    switch (chatExperience?.value) {
        case 'subject-to-articles':
            return <NewChatVariant2 />
        case 'message-to-articles':
            return <NewChatVariant3 />
        default:
            return <NewChatVariant1 />
    }
}
