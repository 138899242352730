import {
    Experiment,
    ExperimentClient,
    Variant
} from '@amplitude/experiment-js-client'
import { FC, ReactNode, createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import { useUserInfo } from 'src/hooks'

const { REACT_APP_AMPLITUDE_EXPERIMENT_CLIENT_ID } = process.env

const AmplitudeExperimentContext = createContext<ExperimentClient | undefined>(
    undefined
)

/**
 * Get the value of an experiment or feature flag
 * Configure experiments in Amplitude Experiment dashboard
 *
 * value is undefined until AE loads user data
 */
export function useAmpliFeatureFlag(
    flag: string,
    withPayload = false
): string | undefined | null | boolean | Variant {
    const experimentClient = useContext(AmplitudeExperimentContext)

    const variant = experimentClient?.variant(flag)

    if (withPayload) {
        return variant
    }

    return variant?.value
}

const amplitudeExperiment = Experiment.initializeWithAmplitudeAnalytics(
    REACT_APP_AMPLITUDE_EXPERIMENT_CLIENT_ID!
)

export const AmplitudeExperimentProvider: FC<{ children: ReactNode }> = ({
    children
}) => {
    const { user } = useUserInfo()

    const { data: experimentClient } = useQuery(
        ['amplitude-experiment-client', user?.id],
        async () =>
            user
                ? amplitudeExperiment.fetch({
                      user_id: user.analyticsUuid,
                      user_properties: {
                          engagementStatus: user.patientEngagementStatus
                      }
                  })
                : undefined,
        {
            enabled: !!user
        }
    )

    return (
        <AmplitudeExperimentContext.Provider value={experimentClient}>
            {children}
        </AmplitudeExperimentContext.Provider>
    )
}
