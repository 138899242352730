import { useEffect, useLayoutEffect, useState } from 'react'
import {
    Container,
    LayoutGrid,
    Spinner,
    TiaModal,
    Button,
    Flex,
    Text,
    Image,
    Heading,
    Paragraph
} from '@asktia/tia-ui'
import { Link } from 'react-router-dom'

import Maintenance from 'src/pages/AppointmentHistory/maintenance'

import { useAppointmentHistory } from 'src/hooks/useAppointmentHistory'
import { AppointmentV1 } from 'src/types'
import { PageHeader } from 'src/components/PageHeader'
import { BillCard } from './BillCard'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import { BackButton } from 'src/components/Blocks/BackButton'
import { BillingInfoCopyBlock } from './BillingInfoCopyBlock'
import {
    Bill,
    CollectableBill,
    useBillsForPatient
} from 'src/pages/AppointmentHistory/EncounterDetail/BillSummary/useBillingSummary'
import { BillingSummary } from './BillingSummary'
import { useDevSidebar } from 'src/DevTools/DevSideBar'
import { useInvoices } from 'src/hooks/useInvoices'
import { useModal } from 'react-modal-hook'
import { CloseIcon } from 'src/components/Blocks/Icons'
import dollarSign from '../../assets/dollar-sign.svg'
import { useAmpli, useLocalStorage } from 'src/hooks'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'

const SAFE_APPOINTMENT_DATE = '2023-11-02'

type SimplifiedMockedAppointment = Pick<
    AppointmentV1,
    'scheduledTime' | 'label'
>

// TODO: put this in more sensible place
export type AppointmentJoinedBill = {
    bill: Bill
    appointment: AppointmentV1 | SimplifiedMockedAppointment
}

type AppointmentLookup = { [key: string]: AppointmentV1 }

// The modal that opens when we load slots
const BookingModal = (props: { hideModal: () => void }) => {
    return (
        <TiaModal sx={{ maxWidth: 380 }}>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'flex-start'
                }}
            >
                <Flex sx={{ alignItems: 'flex-start' }}>
                    <Image src={dollarSign} />

                    <Heading sx={{ ml: '4px' }} variant="h2">
                        Predictable <br />
                        Payments
                    </Heading>
                </Flex>
                <CloseIcon
                    sx={{ cursor: 'pointer' }}
                    onClick={props.hideModal}
                    color="darkIconDefault"
                ></CloseIcon>
            </Flex>

            <Paragraph sx={{ mb: 2, fontSize: 4 }}>
                With Tia's Predictable Payments, you’ll pay your bill over time,
                $100 per month. No interest or extra charges- just more time to
                pay.
            </Paragraph>
            <Paragraph sx={{ fontSize: 4 }}>
                In healthcare, you often don’t know your costs until insurance
                finalizes your insurance claim. With Tia’s Predictable Payments,
                you can predict how much you will pay each month. We’re taking
                the surprise out of surprise medical bills.
            </Paragraph>
            <Flex
                sx={{ alignItems: 'center', flexDirection: 'column', mt: '6' }}
            >
                <Button
                    as={Link}
                    //@ts-ignore
                    to={'https://asktia.com/predictable-payments'}
                    sx={{
                        width: '100% !important'
                    }}
                >
                    Read More
                </Button>
                <Text
                    sx={{
                        color: '#282725',
                        mt: '4',
                        cursor: 'pointer',
                        fontSize: 0,
                        textDecoration: 'underline'
                    }}
                    onClick={props.hideModal}
                >
                    Close
                </Text>
            </Flex>
        </TiaModal>
    )
}

export const useSeenPredictablePayments = () => {
    return useLocalStorage<boolean>(
        'tia:has-seen-predictable-payments-modal',
        false
    )
}

const AppointmentBillingList = () => {
    const { viewAppointmentBilling, selectPayCurrentInvoice } = useAmpli()
    const [hasSeenCarePlan, setHasSeenCarePlan] = useSeenPredictablePayments()

    const { isLoading: isLoadingBills, bills } = useBillsForPatient()
    const { addAction, deleteAction } = useDevSidebar()
    const { invoices } = useInvoices()
    const [mockedType, setMockedType] = useState<
        | {
              currentInvoiceInCents: number
              totalBalanceInCents: number
              dueDate: Date
          }
        | undefined
    >()
    const { appointments, isLoading: isLoadingAppointments } =
        useAppointmentHistory()

    const [showBookingModal, hideBookingModal] = useModal(
        () => <BookingModal hideModal={hideBookingModal} />,
        []
    )

    useEffect(() => {
        if (!hasSeenCarePlan) {
            setHasSeenCarePlan(true)
            showBookingModal()
        }
    }, [])

    /**
     * Billing will return all invoices for a userId.
     * By checking amount remaining and referenceId, we can filter out invoices that have been paid
     * or are open and not associated with an appointment.
     */
    const invoice = (invoices || []).filter(
        i =>
            i.amountRemaining &&
            i.amountRemaining > 0 &&
            i.referenceId &&
            i.referenceId !== ''
    )[0]

    const [appointmentJoinedBills, setAppointmentJoinedBills] = useState<
        AppointmentJoinedBill[]
    >([])

    const [totalBalance, setTotalBalance] = useState(0)

    const getAppointmentJoinedBill = (
        bill: Bill,
        appointmentLookup: AppointmentLookup
    ): AppointmentJoinedBill => {
        const getRealJoinedBill = () => {
            if (appointmentLookup[bill.encounterId]) {
                return {
                    bill,
                    appointment: appointmentLookup[bill.encounterId]
                }
            } else {
                // This should never happen
                // you shouldn't have a claim without an appointment
                // this is just here just in case
                return {
                    bill,
                    appointment: {
                        label: 'non appointment',
                        scheduledTime: new Date() // ensure that mocked bills are after old cutoff
                    }
                }
            }
        }
        const isProduction = window.location.hostname === 'member.asktia.com'

        const realAppointmentsWithClaimIds = Object.values(
            appointmentLookup
        ).filter(appointment => appointment.claimId)

        const joined = isProduction
            ? getRealJoinedBill()
            : {
                  bill,
                  appointment:
                      realAppointmentsWithClaimIds.length > 0
                          ? {
                                ...realAppointmentsWithClaimIds[0],
                                scheduledTime: new Date()
                            }
                          : {
                                scheduledTime: new Date(
                                    '2024-07-16T18:57:06.646Z'
                                ),
                                label: 'mocked appointment'
                            }
              }
        return joined
    }

    const getAppointmentsByClaimId = (): AppointmentLookup => {
        const appointmentLookup: AppointmentLookup = {}
        const isProduction = window.location.hostname === 'member.asktia.com'

        if (isProduction) {
            appointments.forEach(appointment => {
                if (appointment.claimId) {
                    appointmentLookup[appointment.claimId] = appointment
                }
            })
        } else {
            // NOTE: this is working because the mocked claim id on the backend is just '1', '2', ...
            // in production having a claim implies there is an appointment with that claim id
            // so this is purely something to do in non production envs
            appointments.forEach((appointment, i) => {
                appointmentLookup[`${i}`] = appointment
            })
        }

        return appointmentLookup
    }

    useEffect(() => {
        if (
            bills &&
            appointments &&
            bills.length > 0 &&
            appointments.length > 0
        ) {
            const appointmentsByClaimId = getAppointmentsByClaimId()
            const appointmentJoinedBills = bills.map(bill =>
                getAppointmentJoinedBill(bill, appointmentsByClaimId)
            )

            const safeAppointmentDate = new Date(SAFE_APPOINTMENT_DATE)

            const safeBills = appointmentJoinedBills.filter(
                bill => bill.appointment.scheduledTime > safeAppointmentDate
            )

            const remainingBalancesInCents = safeBills
                ?.filter(joinedBill => joinedBill.bill.collectable === true)
                ?.map(
                    joinedBill =>
                        (joinedBill.bill as CollectableBill)
                            .remainingBalanceInCents
                )
                .filter(dollarsInCents => dollarsInCents)
                .reduce((accumulated, value) => accumulated + value, 0)

            setAppointmentJoinedBills(safeBills)

            setTotalBalance(remainingBalancesInCents ?? 0)
        }
    }, [bills, appointments])

    useLayoutEffect(() => {
        addAction({
            id: '1',
            children: <>1</>,
            onClick: () =>
                setMockedType({
                    currentInvoiceInCents: 10000,
                    totalBalanceInCents: 157654,
                    dueDate: new Date(1751243962753)
                })
        })
        addAction({
            id: '2',
            children: <>2</>,
            onClick: () =>
                setMockedType({
                    currentInvoiceInCents: 10000,
                    totalBalanceInCents: 157654,
                    dueDate: new Date(1711243962753)
                })
        })
        addAction({
            id: '3',
            children: <>3</>,
            onClick: () =>
                setMockedType({
                    currentInvoiceInCents: 4879,
                    totalBalanceInCents: 4879,
                    dueDate: new Date(1751243962753)
                })
        })
        addAction({
            id: '4',
            children: <>4</>,
            onClick: () =>
                setMockedType({
                    currentInvoiceInCents: 0,
                    totalBalanceInCents: 0,
                    dueDate: new Date(1751243962753)
                })
        })
        addAction({
            id: '5',
            children: <>5</>,
            onClick: () =>
                setMockedType({
                    currentInvoiceInCents: 0,
                    totalBalanceInCents: 157654,
                    dueDate: new Date(1751243962753)
                })
        })

        return () => {
            deleteAction('1')
            deleteAction('2')
            deleteAction('3')
            deleteAction('4')
            deleteAction('5')
        }
    }, [])

    useEffect(() => {
        viewAppointmentBilling({
            invoiceAmount: invoice?.amountRemaining,
            totalBalance: totalBalance
        })
    }, [])

    const maintenanceMode =
        useAmpliFeatureFlag('billing-maintenance-for-total') === 'on'

    return (
        <>
            <PageHeader
                mobileBack={<BackButton href="/r/your-care" variant="white" />}
            />
            <NavBarHeading sx={{ mb: 5 }}>Appointment Billing</NavBarHeading>

            <LayoutGrid columns="one">
                <Container>
                    {maintenanceMode ? (
                        <Maintenance />
                    ) : (
                        <>
                            {isLoadingBills || isLoadingAppointments ? (
                                <Spinner />
                            ) : (
                                <>
                                    <BillingSummary
                                        currentInvoiceInCents={
                                            mockedType
                                                ? mockedType.currentInvoiceInCents
                                                : invoice?.amountRemaining || 0
                                        }
                                        totalBalanceInCents={
                                            mockedType
                                                ? mockedType.totalBalanceInCents
                                                : totalBalance || 0
                                        }
                                        dueDate={
                                            mockedType
                                                ? mockedType.dueDate
                                                : invoice?.dueDate || new Date()
                                        }
                                        onCTAClick={() => {
                                            selectPayCurrentInvoice({
                                                invoiceAmount:
                                                    invoice?.amountRemaining ||
                                                    0
                                            })
                                            window
                                                ?.open(
                                                    invoice?.hostedUrl,
                                                    '_blank'
                                                )
                                                ?.focus()
                                        }}
                                    />

                                    <BillingInfoCopyBlock />

                                    {appointmentJoinedBills?.map(
                                        (appointmentJoinedBill, i) => (
                                            <BillCard
                                                key={`bill-${i}`}
                                                {...appointmentJoinedBill}
                                            ></BillCard>
                                        )
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Container>
            </LayoutGrid>
        </>
    )
}

export default AppointmentBillingList
