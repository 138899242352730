import { Questionnaire } from '@asktia/tia-fhir-models'
import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import logger from 'src/logger'

export function useQuestionnaire(questionnaireId: string): {
    isLoading: boolean
    questionnaire: Questionnaire
} {
    const query = useQuery<Questionnaire>(
        ['uhr', 'questionnaire', questionnaireId],
        () =>
            fetcher(
                `${BASE_API_URL}/uhr-api/questionnaire/Questionnaire/${questionnaireId}`,
                undefined,
                undefined,
                {
                    customErrorHandler: (res: Response) => {
                        res.text().then(body => {
                            logger.info(`ACI Response - useQuestionnaire`, {
                                questionnaireId,
                                status: res.status,
                                errorBody: body
                            })
                        })

                        return new Error('Server returned not-ok')
                    }
                }
            ),
        {
            cacheTime: Infinity
        }
    )

    return {
        isLoading: query.isLoading,
        questionnaire: query.data || {
            item: [],
            resourceType: 'Questionnaire',
            id: 'personal-wellness',
            title: 'Personal wellness'
        }
    }
}
